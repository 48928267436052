"use client";

import { useEffect } from "react";
import { Tooltip } from "bootstrap";

export default function BootstrapClient(): null {

  useEffect(() => {
    const isClient = typeof window !== "undefined";

    if (isClient) {
      // Dynamically import Bootstrap JS only on the client-side
      import('bootstrap').then((bootstrap) => {
        /*const tooltipTriggerList = document.querySelectorAll('[data-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
          new Tooltip(tooltipTriggerEl);
        });*/
      }).catch((err) => {
        console.error("Error loading Bootstrap:", err);
      });
    }
  }, []);

  return null;
}
